<template>
	<div>
		<div class="workLine">
			<el-card class="workLine" style="width: 20%" shadow="always">提交审批</el-card>
		</div>
		<div v-show="newCardList.length == 0">
			<div class="addNode">
				<div class="addLine"></div>
				<div class="addbtn">
					<el-button type="primary" size="mini" icon="el-icon-plus" @click="handleDialog(0, 'top')" circle></el-button>
				</div>
				<div class="addLine"></div>
			</div>
		</div>
		<div v-for="(card, index) in newCardList" :key="index">
			<div class="addNode">
				<div class="addLine"></div>
				<div class="addbtn">
					<el-button type="primary" size="mini" icon="el-icon-plus" @click="handleDialog(index, 'top')" circle></el-button>
				</div>
				<div class="addLine"></div>
			</div>
			<div>
				<el-card style="width: 20%" class="box-card custCard workLine">
					<div slot="header" class="clearfix">
						<span>审批人</span>
						<el-button icon="el-icon-delete" style="float: right; padding: 3px 0" type="text" @click="delCard(index)"></el-button>
					</div>
					<div>审批人：{{ card.userName }}</div>
				</el-card>
			</div>
			<div v-show="index == newCardList.length - 1" class="addNode">
				<div class="addLine"></div>
				<div class="addbtn">
					<el-button type="primary" size="mini" icon="el-icon-plus" @click="handleDialog(index, 'bottom')" circle></el-button>
				</div>
				<div class="addLine"></div>
			</div>
		</div>
		<div class="workLine">
			<el-card class="workLine" style="width: 20%" shadow="always">结束审批</el-card>
		</div>
		<!-- 创建弹框 -->
		<el-dialog
			width="70%"
			title="选择员工"
			center
			:visible.sync="dialogVisible"
			:before-close="handleClose"
			:close-on-click-modal="false"
			:close-on-press-escape="false"
			append-to-body
			@open="openDialog"
		>
			<div class="search_box">
				<el-input placeholder="请输入姓名或手机号查询" v-model.trim="keyWork" style="width: 40%" @keyup.enter.native="query">
					<i slot="suffix" class="el-input__icon el-icon-search search_icon" @click="query"></i>
				</el-input>
			</div>
			<el-table
				ref="multipleTable"
				:data="staffList"
				tooltip-effect="dark"
				:row-key="getRowKey"
				v-loading="loading"
				class="tableBackground"
				style="width: 100%"
				highlight-current-row
				@current-change="handleSelectChange"
			>
				<!-- <el-table-column type="selection"
                       :reserve-selection="true"
                       width="60"
                       align="center"></el-table-column> -->
				<el-table-column prop="staffName" label="姓名" width align="center"></el-table-column>
				<el-table-column prop="phoneNumber" label="手机号" width align="center"></el-table-column>
				<el-table-column prop="jobName" label="岗位" width align="center"></el-table-column>
				<el-table-column prop="jobNumber" label="工号" width align="center"></el-table-column>
				<el-table-column prop="mailbox" label="工作邮箱" width="200" align="center"></el-table-column>
			</el-table>
			<div style="display: flex; justify-content: flex-end">
				<el-pagination
					style="margin: 20px 0"
					@current-change="handleCurrentChange"
					:current-page="pageNo"
					:page-size="10"
					background
					layout="total,prev, pager, next"
					:total="totalNum"
				></el-pagination>
			</div>

			<span slot="footer" class="dialog-footer">
				<el-button @click="cancle">取 消</el-button>
				<el-button type="primary" @click="addCard">确定</el-button>
			</span>
			<!-- </div> -->
		</el-dialog>
	</div>
</template>
<script>
export default {
	components: {},
	props: {
		cardList: {
			type: Array
		}
	},
	watch: {
		cardList(newVal) {
			this.newCardList = newVal;
		}
	},
	data() {
		return {
			dialogVisible: false,
			form: {
				userName: ''
			},
			rules: {
				userName: [{ required: true, message: '请选择负责人', trigger: 'change' }]
			},
			newCardList: [],
			cardIndex: '',
			position: '',
			staffList: [],
			keyWork: '',
			totalNum: 0,
			pageNo: 1,
			selectList: [],
			loading: false
		};
	},
	mounted() {
		this.$nextTick(() => {});
	},
	methods: {
		openDialog() {
			this.$nextTick(() => {
				this.$refs.multipleTable.clearSelection();
			});
			(this.pageNo = 1), (this.keyWork = '');
			this.getStaffList();
		},
		query() {
			this.getStaffList();
		},
		getRowKey(row) {
			return row.staffId;
		},
		handleCurrentChange(val) {
			this.pageNo = val;
			this.getStaffList();
		},
		// 获取员工列表
		getStaffList() {
			this.loading = true;
			this.$axios
				.post('/v1/org/department/staff/list', {
					request: {
						pageNo: this.pageNo,
						pageSize: 10,
						orgId: '',
						keyWork: this.keyWork
					},
					sign: ''
				})
				.then((res) => {
					this.totalNum = Number(res.totalNum);
					this.staffList = res.list;
					this.loading = false;
				});
		},
		// 表格选择
		handleSelectChange(row) {
			this.selectList = [];
			this.selectList.push({ userId: row.staffId, userName: row.staffName });
		},
		cancle() {
			this.dialogVisible = false;
		},
		handleClose() {
			this.dialogVisible = false;
		},
		handleDialog(index, position) {
			this.dialogVisible = true;
			this.cardIndex = index;
			this.position = position;
		},
		addCard() {
			this.newCardList = [...this.cardList];
			if (this.selectList.length > 0) {
				if (this.position == 'top') {
					this.newCardList.splice(this.cardIndex, 0, this.selectList[0]);
				} else {
					this.$set(this.newCardList, this.cardIndex + 1, this.selectList[0]);
				}
				this.$emit('updateList', this.newCardList);
				this.dialogVisible = false;
			}
		},
		delCard(index) {
			this.newCardList = this.newCardList.splice(index, 1);
		}
	}
};
</script>
<style lang="scss" scoped>
.workLine {
	text-align: center;
	margin: 0 auto;
}
.addLine {
	width: 1px;
	height: 30px;
	margin: 0 auto;
	background: #d8d8d8;
}
.addbtn {
	text-align: center;
}
</style>
<style lang="scss">
.custCard {
	.el-card__header {
		background: #e7f0fd;
		color: #484848;
		text-align: center;
	}
}
.addbtn {
	.el-button--primary {
		background: #fff;
		border: 1px solid #498df0;
		color: #498df0;
	}
}
</style>
