<template>
  <div class="assessBox">
    <div class="app-container">
      <div class="titlebox">
        <el-button size="medium"
                   type="text"
                   @click="$router.go(-1)">返回</el-button>
        <div class="title">申请表</div>
      </div>
      <div class="step">
        <el-steps style="padding-top: 25px;"
                  align-center
                  process-status="finish "
                  :active="active">
          <el-step title="审批表单"></el-step>
          <el-step title="审批流"></el-step>
          <el-step title="高级设置"></el-step>
          <!-- <el-step title="预览"></el-step>  font-size: 14px;
    color: #606266;-->
        </el-steps>
      </div>
      <div class="viewbox">
        <div class="stepWrap">
          <div v-show="active == 0">
            <div>
              <div>
                <div class="titlexIcon">基本信息</div>

              </div>
              <div class="content">
                <div v-for="(item,index) in step1Data.basicPropertiesList "
                     :key="index"
                     style="margin:15px 0">
                  <span class="leftSpan">{{item.approvalPropertyName}}</span>
                  <el-divider direction="vertical"></el-divider>
                  <span style="color: #C0C4CC;">{{item.approvalPropertyTip}}</span>
                </div>
              </div>
              <el-divider></el-divider>
            </div>
            <div>
              <div>
                <div class="titlexIcon">申请类型</div>

              </div>
              <div class="content">
                <div style="margin-bottom: 10px;">
                  <span class="leftSpan">申请类型</span>
                  <el-divider direction="vertical"></el-divider>
                  <span style="color: #C0C4CC;">{{approvalName}}</span>
                </div>
              </div>
              <el-divider></el-divider>
            </div>
            <div>
              <div>
                <div class="titlexIcon">申请详情</div>

              </div>
              <div class="content">
                <div style="text-align:right">
                  <el-button size="mini"
                             icon="el-icon-plus"
                             @click="openCenterModal('add')"
                             type="primary">添加字段</el-button>
                </div>
                <div v-for="(item,index) in step1Data.applyPropertiesList"
                     :key="index"
                     style="margin: 20px 0; display:flex;align-items: center;">
                  <div class="prepertiy"
                       v-if="item.approvalPropertyType != 5 && item.approvalPropertyType != 6">
                    <span class="leftSpan">{{item.approvalPropertyName}}</span>
                    <el-divider direction="vertical"></el-divider>
                    <span style="color: #C0C4CC;">{{item.approvalPropertyTip}}</span>
                  </div>
                  <div class="prepertiy"
                       v-if="item.approvalPropertyType == 5">
                    <span class="leftSpan">{{item.approvalPropertyName}}</span>
                    <el-divider direction="vertical"></el-divider>
                    <div class="preBox">
                      <el-radio v-for="(item,index) in item.approvalPropertyDefaultValue "
                                :key="index"
                                disabled
                                label="禁用">{{item.value}}</el-radio>
                    </div>
                  </div>
                  <div class="prepertiy"
                       v-if="item.approvalPropertyType == 6">
                    <span class="leftSpan">{{item.approvalPropertyName}}</span>
                    <el-divider direction="vertical"></el-divider>
                    <div class="preBox">
                      <el-checkbox v-for="(item,index) in item.approvalPropertyDefaultValue "
                                   :key="index"
                                   disabled
                                   label="禁用">{{item.value}}</el-checkbox>
                    </div>
                  </div>
                  <div v-if="item.fieldType == 0">
                    <el-tag>系统字段</el-tag>
                  </div>
                  <div class="prebtn"
                       v-if="item.fieldType == 1">
                    <el-button type="text"
                               @click="handleEdit(item,index)">编辑</el-button>
                    <el-button type="text"
                               style="color:red"
                               @click="delItem(index)">删除</el-button>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <auditline @updateList="updateList"
                     v-show="active == 1"
                     :approvalInfoId="approvalInfoId"
                     :cardList="cardList"></auditline>
          <div v-show="active == 2">
            <div>
              <div>
                <div class="titlexIcon">审批设置</div>

              </div>
              <el-divider></el-divider>
              <div class="content"
                   style="padding-left:0">
                <div style="margin-bottom:20px;">
                  <span>审批人空缺时：</span>
                  <el-radio v-model="setParams.approvalType"
                            label="1">跳过该审批步骤</el-radio>
                  <el-radio v-model="setParams.approvalType"
                            label="2">停止审批流</el-radio>
                </div>
                <div>
                  <el-checkbox v-model="setParams.opinionStatus"
                               true-label="1"
                               false-label="2">审批人意见</el-checkbox>
                </div>
              </div>
            </div>
          </div>
          <!-- <preview v-show="active == 3"
                   :approvalInfoId="approvalInfoId"></preview> -->
          <el-dialog :title="centerTitle"
                     :visible.sync="centerDialogVisible"
                     width="40%"
                     center>
            <div style="margin-bottom:15px;">
              <el-alert title="单行文本可输入20个字;多行文本可输入200个字符;数值范围在:0-10000000之间"
                        type="info"
                        show-icon
                        :closable="false">
              </el-alert>
            </div>
            <el-form :model="fieldForm"
                     ref="fieldForm"
                     label-width="100px"
                     :rules="fieldRules"
                     class="demo-ruleForm">
              <el-form-item label="字段类型"
                            prop="region">
                <el-select style="width:100%"
                           v-model="fieldForm.approvalPropertyType"
                           placeholder="请选择字段类型">
                  <el-option v-for="(option,index) in propertyType"
                             :key="index"
                             :label="option.dictName"
                             :value="option.dictId"></el-option>
                </el-select>
              </el-form-item>
              <el-form-item label="字段名称"
                            prop="approvalPropertyName">
                <el-input v-model="fieldForm.approvalPropertyName"
                          maxlength="8"
                          placeholder="1-8个字符，不能与现有字段名称重复"></el-input>
              </el-form-item>
              <el-form-item label="字段必填" prop="approvalPropertyRequired" style="margin:-8px 0 14px;">
                <el-switch :active-value="1"
                           :inactive-value="0"
                           v-model="fieldForm.approvalPropertyRequired">
                </el-switch>
              </el-form-item>
              <el-form-item v-show="fieldForm.approvalPropertyType == '1' ||
              fieldForm.approvalPropertyType != '5'&&fieldForm.approvalPropertyType != '6'&&fieldForm.approvalPropertyType != '7'"
                            prop="approvalPropertyTip"
                            label="提示文案">
                <el-input v-model="fieldForm.approvalPropertyTip"
                          maxlength="15"
                          placeholder="0-15个字符"></el-input>
              </el-form-item>
              <el-form-item label="字段选项"
                            v-show="fieldForm.approvalPropertyType == '5' || fieldForm.approvalPropertyType == '6'|| fieldForm.approvalPropertyType == '10'">
                <div v-for="(item,index) in optiongs"
                     :key="index">
                  <el-input style="width:80%;margin-bottom:10px;"
                            v-model="item.value"></el-input>
                  <el-button v-show="optiongs.length > 1"
                             style="margin-left:10px;"
                             type="text"
                             icon="el-icon-top"
                             @click.prevent="setToTop(index)"></el-button>
                  <el-button v-show="optiongs.length > 1"
                             style="margin-left:10px;"
                             type="text"
                             icon="el-icon-bottom"
                             @click.prevent="setToBottom(index)"></el-button>
                  <el-button style="color:red;margin-left:10px;"
                             type="text"
                             icon="el-icon-delete"
                             @click.prevent="removeOption(item)"></el-button>
                </div>
                <el-button type="text"
                           icon="el-icon-plus"
                           @click.prevent="addOption">添加字段选项</el-button>

              </el-form-item>
              <el-form-item v-show="fieldForm.approvalPropertyType == '8'"
                            label="单位"
                            prop="approvalPropertyDefaultValue">
                <el-input v-model="fieldForm.approvalPropertyDefaultValue"
                          placeholder="1-4个字符"></el-input>
              </el-form-item>

            </el-form>
            <span slot="footer"
                  class="dialog-footer">
              <el-button @click="handleClose">取 消</el-button>
              <el-button type="primary"
                         @click="submit">确 定</el-button>
            </span>
          </el-dialog>
        </div>
        <div style="text-align:center;margin-top:20px;">
          <el-button type="primary"
                     v-show="active > 0"
                     @click="active--">上一步</el-button>
          <el-button type="primary"
                     :loading="loading"
                     @click="nextStep">{{active != 2 ?'下一步':'提交'}}</el-button>
        </div>
      </div>
    </div>
  </div>

</template>
<script>
import * as api from '@/api/audit';
import auditline from './auditLine';
// import preview from './preview';
import { isArray } from 'util';
export default {
  components: {
    auditline
    // preview
  },

  data () {
    return {
      active: 0,
      collActive: '1',
      approvalInfoId: '',
      centerTitle: '',
      approvalName: '',
      approvalId: '',
      step1Data: {
        basicPropertiesList: [
        ],
        applyPropertiesList: []
      },
      centerDialogVisible: false,
      fieldForm: {
        'approvalPropertyPosition': '1',
        'approvalPropertyName': '',
        'approvalPropertyType': '1',
        'approvalPropertyTip': '',
        'approvalPropertyRequired': 0,
        'approvalPropertyDefaultValue': '',
        'fieldType': '1'
      },
      optiongs: [{ id: '', value: '' }],
      propertyType: [],
      fieldRules: {
        approvalPropertyName: [{ required: true, message: '请输入字段名称', trigger: 'blur' }]
      },
      approvalPropertiesDtoList: [],
      cardList: [],
      loading: false,
      setParams: {
        'approvalInfoId': '',
        'approvalType': '1',
        'opinionStatus': '2'
      },
      editIndex: ''
    };
  },
  watch: {
    active (newVal) {
      this.active = parseInt(newVal);
      if (this.active == 0) {
        this.getProCon();
      } else if (this.active == 1) {
        this.getProcessDetail();
      }
    }
  },
  mounted () {
    this.$nextTick(function () {
      this.propertyType = this.$store.state.app.dict.filter((item) => item.groupId == 'propertyType');
      this.approvalInfoId = String(this.$route.query.id);
      this.approvalName = this.$route.query.approvalName;
      if (this.$route.query.type) {
        this.setParams.approvalType = String(this.$route.query.type);
      }
      if (this.$route.query.step) {
        this.active = this.$route.query.step;
      }
      if (this.$route.query.opinionStatus) {
        this.setParams.opinionStatus = String(this.$route.query.opinionStatus);
      }

      if (this.active == 0) {
        this.getProCon();
      }
    });
  },
  methods: {
    getProCon () {
      api.getProCon({ request: { approvalInfoId: this.approvalInfoId } }).then((res) => {
        this.step1Data = res;
        for (const data of this.step1Data.applyPropertiesList) {
          if (data.approvalPropertyType == '5' || data.approvalPropertyType == '6') {
            data.approvalPropertyDefaultValue = JSON.parse(data.approvalPropertyDefaultValue);
          }
        }
      });
    },
    openCenterModal (type) {
      if (type == 'add') {
        this.centerTitle = '添加字段';
        // 重置
        this.fieldForm = {
          'approvalPropertyPosition': '1',
          'approvalPropertyName': '',
          'approvalPropertyType': '1',
          'approvalPropertyTip': '',
          'approvalPropertyRequired': 0,
          'approvalPropertyDefaultValue': '',
          'fieldType': '1'
        };
      }
      this.optiongs = [];
      this.centerDialogVisible = true;
    },
    addOption () {
      this.optiongs.push({
        id: '',
        value: ''
      });
    },
    removeOption (item) {
      const index = this.optiongs.indexOf(item);

      if (index !== -1) {
        this.optiongs.splice(index, 1);
      }
    },
    setToTop (index) {
      if (index == 0) {return;}
      const tempOption = this.optiongs[index - 1];

      this.$set(this.optiongs, index - 1, this.optiongs[index]);
      this.$set(this.optiongs, index, tempOption);
    },
    setToBottom (index) {
      if (index == (this.optiongs.length - 1)) {return;}
      const tempOption = this.optiongs[index + 1];

      this.$set(this.optiongs, index + 1, this.optiongs[index]);
      this.$set(this.optiongs, index, tempOption);
    },
    handleClose () {
      this.$refs['fieldForm'].clearValidate();
      this.$refs['fieldForm'].resetFields();
      this.centerDialogVisible = false;
    },
    submit () {
      this.$refs['fieldForm'].validate((valid) => {
        if (valid) {
          if (this.fieldForm.approvalPropertyType == '5' || this.fieldForm.approvalPropertyType == '6' || this.fieldForm.approvalPropertyType == '10') {
            if (this.optiongs.length > 0) {
              for (const option of this.optiongs) {
                option.id = option.value;
              }
              this.fieldForm.approvalPropertyDefaultValue = JSON.stringify(this.optiongs);
            } else {
              this.$message.error('字段选项不能为空');
              return;
            }
          }
          // 编辑
          if (this.fieldForm.id) {
            this.step1Data.applyPropertiesList[this.editIndex] = this.fieldForm;
          } else {// 新增
            if (this.centerTitle == '编辑字段') { // 未保存情况下编辑
              this.step1Data.applyPropertiesList[this.editIndex] = this.fieldForm;
            } else {
              this.step1Data.applyPropertiesList.push({ ...this.fieldForm });
            }

          }
          for (const data of this.step1Data.applyPropertiesList) {
            if (data.approvalPropertyType == '5' || data.approvalPropertyType == '6' || data.approvalPropertyType == '10') {
              if (!isArray(data.approvalPropertyDefaultValue)) {
                data.approvalPropertyDefaultValue = JSON.parse(data.approvalPropertyDefaultValue);
              }
            }
          }
          this.$refs['fieldForm'].clearValidate();
          this.optiongs = [];
          this.centerDialogVisible = false;
        }
      });
    },
    handleEdit (item, index) {
      this.fieldForm = { ...item };
      if (this.fieldForm.approvalPropertyType == '5' || this.fieldForm.approvalPropertyType == '6' || this.fieldForm.approvalPropertyType == '10') {
        if (!isArray(this.fieldForm.approvalPropertyDefaultValue)) {
          this.optiongs = JSON.parse(this.fieldForm.approvalPropertyDefaultValue);
        } else {
          this.optiongs = [...this.fieldForm.approvalPropertyDefaultValue];
        }
        this.fieldForm.approvalPropertyDefaultValue = JSON.stringify(this.fieldForm.approvalPropertyDefaultValue);
      }
      this.fieldForm.approvalPropertyType = String(this.fieldForm.approvalPropertyType);
      this.centerTitle = '编辑字段';
      this.centerDialogVisible = true;
      this.editIndex = index;
    },
    delItem (index) {
      this.step1Data.applyPropertiesList.splice(index, 1);
    },
    nextStep () {
      this.loading = true;
      if (this.active == 0) { // 审批表单
        this.step1Data.basicPropertiesList.approvalPropertyPosition = '0';
        const tempArr = JSON.parse(JSON.stringify(this.step1Data.applyPropertiesList));

        for (const arr of tempArr) {
          if (arr.approvalPropertyType == '5' || arr.approvalPropertyType == '6' || arr.approvalPropertyType == '10') {
            if (isArray(arr.approvalPropertyDefaultValue)) {
              arr.approvalPropertyDefaultValue = JSON.stringify(arr.approvalPropertyDefaultValue);
            }
          }
        }
        // 把基本信息合并在一起提交
        const arr = [...this.step1Data.basicPropertiesList];

        for (const item of arr) {
          tempArr.push(item);
        }

        const params = {
          request: {
            approvalInfoId: this.approvalInfoId,
            approvalPropertiesDtoList: tempArr
          }
        };

        api.saveProperty(params).then((res) => {
          if (res.code == 0) {
            this.active++;
          }
          this.loading = false;
        });
      } else if (this.active == 1) {// 审批流
        if (this.cardList.length == 0) {
          this.$message.error('请至少添加一位审批人');
          this.loading = false;
          return;
        }
        const arr = [];

        for (const card of this.cardList) {
          arr.push({ userId: card.userId });
        }
        const params = {
          request: {
            approvalInfoId: this.approvalInfoId,
            approvalProcessDtoList: arr
          }
        };

        api.saveProcess(params).then((res) => {
          if (res.code == 0) {
            this.active++;
          }
          this.loading = false;
        });
      } else if (this.active == 2) {// 高级设置
        //   console.log("qqqq")
        this.setParams.approvalInfoId = this.approvalInfoId;
        api.saveSenior({ request: this.setParams }).then((res) => {
          if (res.code == 0) {
            // this.active++;
          api.switchStatus({
              request: {
                approvalInfoId: this.approvalInfoId,
                approvalSwitchStatus: 1
              }
            })
            .then((res) => {
              if (res.code == 0) {
                this.$router.push({ path: '/approval' });
              }
            });

          }
          this.loading = false;
        });
      }
    },
    getProcessDetail () {
      api.getProcessDetail({ request: { approvalInfoId: this.approvalInfoId } }).then((res) => {
        this.cardList = res;
      });
    },
    updateList (list) {
      this.cardList = list;
    }
  }
};
</script>
<style lang="scss" scoped>
.assessBox .app-container .viewbox .level_box_now {
  font-weight: bold;
}
.assessBox {
  .app-container {
    color: #484848;
    padding: 0;
    background-color: #f6f7fb;
    .titlebox {
      height: 55px;
      text-align: center;
      line-height: 17px;
      background: #fff;
      font: bold 18px/25px "Microsoft YaHei";
      padding: 14px 40px;
      > .el-button {
        float: left;
        color: #484848;
        transform: translateY(-8px);
        font-size: 16px;
      }
      text-align: center;
      line-height: 17px;
      font: bold 18px/25px "Microsoft YaHei";
      margin: 20px;
    }
    .viewbox {
      margin: 0 20px;
      background: #ffffff;
      min-height: 300px;
      padding: 50px;
    }
  }
}
p {
  margin-top: 0px;
}
.step {
  background: #fff;
  height: 100px;
  margin: 20px;
}
.stepWrap {
  margin-top: 20px;
  .content {
    padding: 10px 75px;
  }
  .titlexIcon {
    font-size: 18px;
    font-weight: bold;
    &:before {
      display: inline-block;
      width: 8px;
      height: 20px;
      background: #498df0;
      content: " ";
      border-radius: 5px;
      vertical-align: top;
      margin-right: 5px;
    }
}
}
.titleTip {
  height: 30px;
}
.leftSpan {
  width: 150px;
  display: inline-block;
  text-align: right;
}

.prepertiy {
  width: 92%;
}
// .prebtn {
//   margin-left: 20px;
// }
.preBox {
  width: 300px;
  display: inline-block;
  vertical-align: middle;
}
</style>

